var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.edit
      ? _c("div", { staticClass: "mt-3 text-center" }, [
          _c("p", [
            _c("strong", [
              _vm._v(
                _vm._s(_vm.$t("modals.addUser.name")) + ": " + _vm._s(_vm.name)
              ),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("modals.addUser.email")) +
                  ": " +
                  _vm._s(_vm.email)
              )
            ])
          ])
        ])
      : _c("div", { staticClass: "user-and-email" }, [
          _c(
            "div",
            { staticClass: "mt-4 mx-5 mb-3" },
            [
              _c("b-form-input", {
                attrs: {
                  id: "name-input",
                  placeholder: _vm.$t("modals.addUser.name"),
                  autofocus: "",
                  debounce: "500",
                  disabled: _vm.edit
                },
                on: {
                  update: _vm.setName,
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.$emit("enter-pressed")
                  }
                },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4 mx-5 mb-3" },
            [
              _c("b-form-input", {
                attrs: {
                  id: "email-input",
                  placeholder: _vm.$t("modals.addUser.email"),
                  state: _vm.validateState(),
                  "aria-describedby": "email-live-feedback",
                  debounce: "500",
                  disabled: _vm.edit
                },
                on: {
                  input: _vm.resetEmailError,
                  update: _vm.setEmail,
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.$emit("enter-pressed")
                  }
                },
                model: {
                  value: _vm.$v.form.email.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.form.email,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.form.email.$model"
                }
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { id: "email-live-feedback" } },
                [
                  !_vm.$v.form.email.email
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(" " + _vm._s(_vm.$t("login.invalidEmail")) + " ")
                      ])
                    : _vm._e(),
                  !_vm.$v.form.email.b2bEmailValidation
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("register.notBusinessEmail")) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ]),
    _c(
      "div",
      { staticClass: "mx-5 mb-4" },
      [
        _c(
          "b-dropdown",
          {
            attrs: {
              "menu-class": "w-100",
              id: "setRole",
              block: "",
              text: _vm.dropdownText,
              disabled: _vm.edit && _vm.isLastAdmin
            },
            model: {
              value: _vm.role,
              callback: function($$v) {
                _vm.role = $$v
              },
              expression: "role"
            }
          },
          _vm._l(_vm.inTeam ? _vm.teamRoles : _vm.groupRoles, function(
            r,
            index
          ) {
            return _c(
              "div",
              {
                key: r.role_id,
                staticClass: "w-100",
                style:
                  r.name === "Account Manager"
                    ? { backgroundColor: "rgba(220, 83, 83, 0.7)" }
                    : "",
                attrs: { value: r }
              },
              [
                _c(
                  "b-dropdown-item-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setRole(r)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(r.name))]
                ),
                _c("b-dropdown-text", [
                  _vm._v(" " + _vm._s(_vm.$t(`roles.${r.handle}`)))
                ]),
                index !==
                (_vm.inTeam ? _vm.teamRoles : _vm.groupRoles).length - 1
                  ? _c("b-dropdown-divider")
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }