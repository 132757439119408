var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "context-picker-modal" },
    [
      _c("b-modal", {
        ref: "modal-create-context",
        attrs: {
          id: "modal-create-context",
          "footer-class": "flex-nowrap",
          size: "lg",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _vm.isElementUpdate
                  ? _c("h3", [
                      _vm._v(_vm._s(_vm.$t("modals.createContext.updateTitle")))
                    ])
                  : _c("h3", [
                      _vm._v(_vm._s(_vm.$t("modals.createContext.title")))
                    ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", { staticClass: "m-4" }, [
                  _vm.source === "urlParameter"
                    ? _c(
                        "div",
                        [
                          _c("context-item-url-param", {
                            attrs: {
                              "operator-options": _vm.operatorOptions,
                              "selected-left-value": _vm.selectedLeftValue,
                              "selected-operator": _vm.selectedOperator,
                              "selected-right-value": _vm.selectedRightValue
                            },
                            on: {
                              "set-url-parameter": _vm.setUrlParameter,
                              "trigger-create-update-context":
                                _vm.createUpdateEntry
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c("label", { attrs: { for: "value-name" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("modals.createContext.set")
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _vm.personalizationSetData
                                    ? _c(
                                        "div",
                                        { staticClass: "d-inline-block" },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.personalizationSetData
                                            },
                                            on: {
                                              change:
                                                _vm.changePersonalizationSet
                                            },
                                            model: {
                                              value: _vm.personalizationSet,
                                              callback: function($$v) {
                                                _vm.personalizationSet = $$v
                                              },
                                              expression: "personalizationSet"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("b-form-text", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("modals.createContext.setDesc")
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                  _vm.source !== "urlParameter"
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "usePersonalizationSetAsContext",
                                        name: "usePersonalizationSetAsContext",
                                        value: true,
                                        "unchecked-value": false
                                      },
                                      model: {
                                        value:
                                          _vm.usePersonalizationSetAsContext,
                                        callback: function($$v) {
                                          _vm.usePersonalizationSetAsContext = $$v
                                        },
                                        expression:
                                          "usePersonalizationSetAsContext"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "modals.createContext.usePersonalizationSetAsContext"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.usePersonalizationSetAsContext
                            ? _c(
                                "b-row",
                                { staticClass: "mb-4" },
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "value-name" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "modals.createContext.value"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("b-col", { attrs: { cols: "8" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            options:
                                              _vm.personalizationFieldValues
                                          },
                                          model: {
                                            value: _vm.leftValue,
                                            callback: function($$v) {
                                              _vm.leftValue = $$v
                                            },
                                            expression: "leftValue"
                                          }
                                        }),
                                        _c("b-form-text", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "modals.createContext.valueDesc"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c("label", { attrs: { for: "value-name" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("modals.createContext.operator")
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-inline-block" },
                                    [
                                      _c("b-form-select", {
                                        attrs: { options: _vm.operatorOptions },
                                        model: {
                                          value: _vm.operator,
                                          callback: function($$v) {
                                            _vm.operator = $$v
                                          },
                                          expression: "operator"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("b-form-text", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "modals.createContext.operatorDesc"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.usePersonalizationSetAsContext
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "value-name" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "modals.createContext.rightValue"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "entry-name",
                                          debounce: "500"
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.enterKeyPressed.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.rightValue,
                                          callback: function($$v) {
                                            _vm.rightValue = $$v
                                          },
                                          expression: "rightValue"
                                        }
                                      }),
                                      _c("b-form-text", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "modals.createContext.rightValueDesc"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel-modal")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _vm.isElementUpdate
                  ? _c(
                      "b-button",
                      {
                        staticClass: "p-3",
                        attrs: {
                          variant: "primary",
                          block: "",
                          disabled: _vm.disableCreateButton
                        },
                        on: { click: _vm.updateEntry }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.update")) + " ")]
                    )
                  : _c(
                      "b-button",
                      {
                        staticClass: "p-3",
                        attrs: {
                          variant: "primary",
                          block: "",
                          disabled: _vm.disableCreateButton
                        },
                        on: { click: _vm.createEntry }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.create")) + " ")]
                    )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }