<template>
  <div>
    <div v-if="edit" class="mt-3 text-center">
      <p><strong>{{ $t('modals.addUser.name') }}: {{ name }}<br>
      {{ $t('modals.addUser.email') }}: {{ email }}</strong></p>
    </div>
    <div v-else class="user-and-email">
      <div class="mt-4 mx-5 mb-3">
        <b-form-input
          id="name-input"
          v-model="name"
          :placeholder="$t('modals.addUser.name')"
          @update="setName"
          autofocus
          debounce="500"
          :disabled="edit"
          @keyup.enter="$emit('enter-pressed');"
        >
        </b-form-input>
      </div>
      <div class="mt-4 mx-5 mb-3">
        <b-form-input
          id="email-input"
          v-model="email"
          v-model.trim="$v.form.email.$model"
          :placeholder="$t('modals.addUser.email')"
          :state="validateState()"
          @input="resetEmailError"
          aria-describedby="email-live-feedback"
          @update="setEmail"
          debounce="500"
          :disabled="edit"
          @keyup.enter="$emit('enter-pressed');"
        >
        </b-form-input>
        <b-form-invalid-feedback id="email-live-feedback">
          <div class="error" v-if="!$v.form.email.email">
            {{ $t('login.invalidEmail') }}
          </div>
          <div class="error" v-if="!$v.form.email.b2bEmailValidation">
            {{ $t('register.notBusinessEmail') }}
          </div>
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="mx-5 mb-4">
      <b-dropdown menu-class="w-100" id="setRole" block :text="dropdownText" v-model="role"
                  :disabled="edit && isLastAdmin">
        <div class="w-100" v-for="(r, index) in (inTeam ? teamRoles : groupRoles)"
             v-bind:style=
                 "(r.name==='Account Manager'?{backgroundColor: 'rgba(220, 83, 83, 0.7)'}:'')"
             :key="r.role_id" :value="r">
          <b-dropdown-item-button  @click="setRole(r)">
            {{ r.name }}</b-dropdown-item-button>
          <b-dropdown-text>
            {{ $t(`roles.${r.handle}`) }}</b-dropdown-text>
          <b-dropdown-divider
              v-if="index !== (inTeam ? teamRoles : groupRoles).length-1">
          </b-dropdown-divider>
        </div>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import GetGroupRoles from '@/graphQlQueries/queries/getGroupRoles';
import GetTeamRoles from '@/graphQlQueries/queries/getTeamRoles';
import emailBlackList from '@/defaultData/nonBusinessEmailBlacklist';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

const b2bEmailValidation = (value) => {
  if (!value) {
    return true;
  }
  return !emailBlackList.some((element) => value.includes(element));
};

export default {
  name: 'ModalInviteUserAndSetRole',
  mixins: [validationMixin],
  props: [
    'userName',
    'userEmail',
    'userRole',
    'isSysAdmin',
    'userPrivileges',
    'edit',
    'isLastAdmin',
    'inTeam',
    'currentUserRole',
  ],
  data() {
    return {
      name: this.edit ? this.userName : null,
      showEmailError: false,
      form: {
        email: this.edit ? this.userEmail : null,
      },
      email: this.edit ? this.userEmail : null,
      role: this.userRole ? this.userRole : null,
      groupRoles: [],
      teamRoles: [],
      allAvailableRoles: [],
      currentUserRoleLevel: null,
      dropdownText: this.userRole ? this.userRole.name : this.$t('modals.inviteUser.disabled'),
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        b2bEmailValidation,
      },
    },
  },
  apollo: {
    groupRoles: {
      query: GetGroupRoles,
      update(data) {
        this.allAvailableRoles = data.roles;
        const currentUserRoleObj = this.allAvailableRoles.find(
          (role) => role.handle === this.currentUserRole,
        );
        if (currentUserRoleObj) {
          this.currentUserRoleLevel = currentUserRoleObj.level;
        }
        const roles = [];
        data.roles.forEach((item) => {
          // only add role if user is sysadmin or has a lower or equal level than the role
          if (this.isSysAdmin || this.currentUserRoleLevel <= item.level) {
            const element = {
              role_id: item.role_id,
              name: item.name,
              handle: item.handle,
              description: item.description,
            };
            roles.push(element);
          }
        });
        return roles;
      },
      skip() {
        return this.inTeam;
      },
      fetchPolicy: 'network-only',
    },
    teamRoles: {
      query: GetTeamRoles,
      update(data) {
        const roles = [];
        data.roles.forEach((item) => {
          const element = {
            role_id: item.role_id,
            name: item.name,
            handle: item.handle,
            description: item.description,
          };
          roles.push(element);
        });
        return roles;
      },
      skip() {
        return !this.inTeam;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    validateState() {
      const { $dirty, $error } = this.$v.form.email;
      console.log({ $dirty, $error });
      return $dirty ? !$error : null;
    },
    setName(value) {
      this.$emit('update-fields', 'name', value);
    },
    setEmail(value) {
      this.$emit('update-fields', 'email', value);
      if (!this.$v.form.$anyError) {
        this.$emit('update-fields', 'validEmail', true);
        console.log('valid email');
      } else {
        this.$emit('update-fields', 'validEmail', false);
        console.log('non  valid email');
      }
    },
    setRole(value) {
      this.dropdownText = value.name;
      this.$emit('update-fields', 'role', value);
    },
    resetEmailError() {
      this.showEmailError = false;
    },
  },
};
</script>

<style scoped>

</style>
